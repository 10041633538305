/**
 * =============================================================================
 * nav.js
 * =============================================================================

 * =============================================================================
 */

// import imagesLoaded from "imagesloaded";
var imagesLoaded = require('imagesloaded');

var Custom = {
  init: function () {
    console.log("[C] Init!");
    // var grid = document.querySelector('.illusions-category');
    // const selectElement = document.querySelector(".tag-select");
    // const result = document.querySelector(".result");
    //
    // imagesLoaded(grid, function() {
    //     var isotope = new Isotope(grid,
    //         {
    //             itemSelector: '.illusion-tile',
    //             columnWidth: '500',
    //         }
    //     );
    //
    //     selectElement.addEventListener("change", (event) => {
    //         isotope.arrange({filter: event.target.value})
    //
    //     });
    // })

    function createMap() {
      var latLng = new google.maps.LatLng(55.873251, -4.285256);
      var map = new google.maps.Map(document.getElementById('mp-map'), {
        zoom: 13,
        center: latLng,
        mapTypeId: google.maps.MapTypeId.satellite,
        styles: [
          {
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [
              {
                "saturation": "2"
              },
              {
                "visibility": "simplified"
              }
            ]
          },
          {
            "featureType": "administrative",
            "elementType": "labels",
            "stylers": [
              {
                "saturation": "-28"
              },
              {
                "lightness": "-10"
              },
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#444444"
              }
            ]
          },
          {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
              {
                "color": "#f2f2f2"
              }
            ]
          },
          {
            "featureType": "landscape",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "saturation": "-1"
              },
              {
                "lightness": "-12"
              }
            ]
          },
          {
            "featureType": "landscape.natural",
            "elementType": "labels.text",
            "stylers": [
              {
                "lightness": "-31"
              }
            ]
          },
          {
            "featureType": "landscape.natural",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "lightness": "-74"
              }
            ]
          },
          {
            "featureType": "landscape.natural",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "lightness": "65"
              }
            ]
          },
          {
            "featureType": "landscape.natural.landcover",
            "elementType": "geometry",
            "stylers": [
              {
                "lightness": "-15"
              }
            ]
          },
          {
            "featureType": "landscape.natural.landcover",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "lightness": "0"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
              {
                "saturation": -100
              },
              {
                "lightness": 45
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "visibility": "on"
              },
              {
                "saturation": "0"
              },
              {
                "lightness": "-9"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "lightness": "-14"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels",
            "stylers": [
              {
                "lightness": "-35"
              },
              {
                "gamma": "1"
              },
              {
                "weight": "1.39"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "lightness": "-19"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "lightness": "46"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "simplified"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.icon",
            "stylers": [
              {
                "lightness": "-13"
              },
              {
                "weight": "1.23"
              },
              {
                "invert_lightness": true
              },
              {
                "visibility": "simplified"
              },
              {
                "hue": "#ff0000"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
              {
                "color": "#adadad"
              },
              {
                "visibility": "on"
              }
            ]
          }
        ]
      });
      var image = {
        url: "/images/layout/map-marker.png", // url
        scaledSize: new google.maps.Size(45, 70), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(22, 75) // anchor
      };
      var marker = new google.maps.Marker({
        position: latLng,
        icon: image,
        title: 'CSPE',
        map: map
      });
    }

    function initMap() {
      if ($("body").hasClass("mp-contact")) {
        createMap();
        mapOverlay();
      }
    }
  }
}
//
export default Custom;

// (function($, window, document) {
//   $(function() {
//
//     $grid = $('.mp-illusions-category');
//
//     var hashID = "." + window.location.hash.substring(1);
//     if(hashID != "."){
//       $('.mp-select-filter.mp-select2').val(hashID);
//     }
//
//     //Illusions Index
//
//     $('.mp-illusions-category').imagesLoaded( function() {
//       // images have loaded
//       if(window.location.hash) { //If we have a hash filter by it, if not don't try
//         $grid.isotope({
//          itemSelector: '.mp-tile',
//          stamp: '.mp-stamp',
//          filter: hashID
//         });
//         var itemElems = $grid.isotope('getFilteredItemElements');
//         var mpAmount = itemElems.length;
//         $('.mp-counter .mp-number').text(mpAmount);
//         $('#mp-tag-filter').trigger('change');
//       }
//       else {
//         $grid.isotope({
//           // options
//           itemSelector: '.mp-tile',
//           stamp: '.mp-stamp',
//           sortBy: 'mpDate',
//           sortAscending: false,
//           getSortData: {
//             mpHits: '.mphits',
//             mpDate: '.mppublishup'
//           }
//         });
//       }
//     });
//
//     $('.mp-select-filter.mp-sort').on('change', function() {
//       var sorter = '';
//       var sortAscending = false;
//       if($(this).val() === "Sort By") {
//         $('.mp-tile.mp-stamp').show();
//       }
//       else {
//         $('.mp-tile.mp-stamp').hide();
//       }
//       $('.mp-select-filter.mp-sort').each(function(i, e){
//           sorter = $('option:selected', this).attr('data-sort-by');
//           sortAscending = $('option:selected', this).attr('data-mp-sort-direction');
//           sortAscending = sortAscending === "asc" ? true : false;
//       });
//       $grid.isotope({
//         sortBy: sorter,
//         sortAscending : sortAscending,
//       });
//     });
//
//     // bind filter select change click
//     $('.mp-select-filter.mp-select2').on('change', function() {
//
//         if($(this).val() === null) {
//           $('.mp-tile.mp-stamp').show();
//         }
//         else {
//           $('.mp-tile.mp-stamp').hide();
//         }
//         var filters = $('.mp-select-filter.mp-select2').val();
//
//         // If filters is null / no value, force it to an array (fixes Clear button issue)
//         if(filters === null)
//           filters = [];
//
//         // 'All' filter found? Remove it - all is not a valid filter
//         var index = $.inArray("all", filters);
//         if (index != -1) {
//             filters.splice(index, 1);
//         }
//
//         // No filters selected? Select all items.
//         if(filters.length === 0) {
//             filters.push("*");
//         }
//
//         // Hide options that aren't options anymore
//         $("#mp-tag-filter option").each(function(i, e){
//           var tag = $(this).val();
//           tag = tag.substring(1,tag.length);
//
//           var numApplicableTiles = 0;
//           if(filters[0] == "*"){
//             numApplicableTiles = $(this).attr('data-mp-inital-count');
//           } else {
//             // Check each tile to see if at least 1 has matching tag constraints
//             $('.mp-illusion-tile').each(function(i, tile){
//
//               var hasAllClasses = true;
//               // Skip stamp
//               if($(tile).hasClass('mp-stamp'))
//                 return true; // continue each, skip this
//
//               // Does the tile have all filter classes AND this tag?
//               if(!$(tile).hasClass(tag)){
//                 hasAllClasses = false;
//               }
//
//               // Now check filters themselves
//               $(filters).each(function(i, filter){
//                 var reducedFilter = filter.substring(1, filter.length);
//
//                 // Skip tag if it matches filter
//                 if(tag == reducedFilter)
//                   return true;
//                 // If tile doesn't have this filter, it fails test
//                 if(!$(tile).hasClass(reducedFilter)){
//                   hasAllClasses = false;
//                   return false;
//                 }
//               });
//
//               // Got all classes? Then this tile is good! Keep the option
//               if(hasAllClasses){
//                 numApplicableTiles++;
//               }
//             });
//           }
//
//           // Disable tag if not available, enable otherwise
//           if(numApplicableTiles == 0){
//             $(e).prop('disabled', true);
//             $(e).text($(e).text().replace(/\((.+?)\)/g, "(0)"));
//           } else {
//             $(e).prop('disabled', false);
//             $(e).text($(e).text().replace(/\((.+?)\)/g, "("+numApplicableTiles+")"));
//           }
//         });
//
//         // Kill
//         setTimeout(function(){
//           $('.mp-select-filter.mp-select2').select2('destroy');
//           initSelect2();
//         }, 10);
//
//         // Apply Isotope with given filters
//         var selector = filters.join('');
//         // use filterFn if matches value
//         $grid.isotope({ filter: selector });
//         // get filtered item elements
//         var itemElems = $grid.isotope('getFilteredItemElements');
//         var mpAmount = itemElems.length;
//         $('.mp-counter .mp-number').text(mpAmount);
//     });
//
// /*    $('.mp-clear-filters-label').click(function() {
//         $grid.isotope({ filter: '*' });
//     });*/
//
//     //Select2 for illusion index filter
//     function initSelect2(){
//       $('.mp-select-filter.mp-select2').select2({
//         placeholder: 'Filter by Tags',
//         allowClear: true
//       });
//     }
//     initSelect2();
//
//
//     //Twitter Feed
//     $('.mp-twitter-feed').twittie({
//       'hideReplies': true,
//       'apiPath':'/api/tweet.php',
//       'dateFormat':'%d/%m/%Y',
//       'count':3,
//     });
//
//
//     function mapOverlay() {
//         $('.mp-overlay').click(function() {
//             $(this).css('pointer-events', 'none');
//         });
//     }
//     function createMap() {
//         var latLng = new google.maps.LatLng(55.873251, -4.285256);
//         var map = new google.maps.Map(document.getElementById('mp-map'), {
//             zoom: 13,
//             center: latLng,
//             mapTypeId: google.maps.MapTypeId.satellite,
//             styles:[
//               {
//                   "featureType": "administrative",
//                   "elementType": "geometry",
//                   "stylers": [
//                       {
//                           "saturation": "2"
//                       },
//                       {
//                           "visibility": "simplified"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "administrative",
//                   "elementType": "labels",
//                   "stylers": [
//                       {
//                           "saturation": "-28"
//                       },
//                       {
//                           "lightness": "-10"
//                       },
//                       {
//                           "visibility": "on"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "administrative",
//                   "elementType": "labels.text.fill",
//                   "stylers": [
//                       {
//                           "color": "#444444"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "landscape",
//                   "elementType": "all",
//                   "stylers": [
//                       {
//                           "color": "#f2f2f2"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "landscape",
//                   "elementType": "geometry.fill",
//                   "stylers": [
//                       {
//                           "saturation": "-1"
//                       },
//                       {
//                           "lightness": "-12"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "landscape.natural",
//                   "elementType": "labels.text",
//                   "stylers": [
//                       {
//                           "lightness": "-31"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "landscape.natural",
//                   "elementType": "labels.text.fill",
//                   "stylers": [
//                       {
//                           "lightness": "-74"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "landscape.natural",
//                   "elementType": "labels.text.stroke",
//                   "stylers": [
//                       {
//                           "lightness": "65"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "landscape.natural.landcover",
//                   "elementType": "geometry",
//                   "stylers": [
//                       {
//                           "lightness": "-15"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "landscape.natural.landcover",
//                   "elementType": "geometry.fill",
//                   "stylers": [
//                       {
//                           "lightness": "0"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "poi",
//                   "elementType": "all",
//                   "stylers": [
//                       {
//                           "visibility": "off"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "road",
//                   "elementType": "all",
//                   "stylers": [
//                       {
//                           "saturation": -100
//                       },
//                       {
//                           "lightness": 45
//                       }
//                   ]
//               },
//               {
//                   "featureType": "road",
//                   "elementType": "geometry",
//                   "stylers": [
//                       {
//                           "visibility": "on"
//                       },
//                       {
//                           "saturation": "0"
//                       },
//                       {
//                           "lightness": "-9"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "road",
//                   "elementType": "geometry.stroke",
//                   "stylers": [
//                       {
//                           "lightness": "-14"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "road",
//                   "elementType": "labels",
//                   "stylers": [
//                       {
//                           "lightness": "-35"
//                       },
//                       {
//                           "gamma": "1"
//                       },
//                       {
//                           "weight": "1.39"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "road",
//                   "elementType": "labels.text.fill",
//                   "stylers": [
//                       {
//                           "lightness": "-19"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "road",
//                   "elementType": "labels.text.stroke",
//                   "stylers": [
//                       {
//                           "lightness": "46"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "road.highway",
//                   "elementType": "all",
//                   "stylers": [
//                       {
//                           "visibility": "simplified"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "road.highway",
//                   "elementType": "labels.icon",
//                   "stylers": [
//                       {
//                           "lightness": "-13"
//                       },
//                       {
//                           "weight": "1.23"
//                       },
//                       {
//                           "invert_lightness": true
//                       },
//                       {
//                           "visibility": "simplified"
//                       },
//                       {
//                           "hue": "#ff0000"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "road.arterial",
//                   "elementType": "labels.icon",
//                   "stylers": [
//                       {
//                           "visibility": "off"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "transit",
//                   "elementType": "all",
//                   "stylers": [
//                       {
//                           "visibility": "off"
//                       }
//                   ]
//               },
//               {
//                   "featureType": "water",
//                   "elementType": "all",
//                   "stylers": [
//                       {
//                           "color": "#adadad"
//                       },
//                       {
//                           "visibility": "on"
//                       }
//                   ]
//               }
//           ]
//         });
//         var image = {
//             url: "/images/layout/map-marker.png", // url
//             scaledSize: new google.maps.Size(45, 70), // scaled size
//             origin: new google.maps.Point(0, 0), // origin
//             anchor: new google.maps.Point(22, 75) // anchor
//         };
//         var marker = new google.maps.Marker({
//             position: latLng,
//             icon: image,
//             title: 'CSPE',
//             map: map
//         });
//     }
//
//     function initMap() {
//       if ($("body").hasClass("mp-contact")) {
//         createMap();
//         mapOverlay();
//       }
//     }
//
//     //Function to copy elements content to clipboard
//
//     function copyToClipboard(elementId) {
//       var mpTempInput = document.createElement("input");
//       mpTempInput.setAttribute("value", document.getElementById(elementId).innerHTML);
//       document.body.appendChild(mpTempInput);
//       mpTempInput.select();
//       document.execCommand("copy");
//       document.body.removeChild(mpTempInput);
//     }
//
//     $(".mp-picture-rollover-state").click(function () {
//       $(this).toggleClass("mp-hover");
//     });
//
//     google.maps.event.addDomListener(window, 'load', initMap);
//
//     var illusionSlider;
//     if ($("body").hasClass("mp-illusion")) {
//       document.getElementById("copyButton").addEventListener("click", function() {
//           copyToClipboard('mp-citation');
//           $(".mp-alert").fadeIn();
//           setTimeout(function(){
//             $(".mp-alert").fadeOut();
//           }, 1000);
//       });
//       illusionSlider = UIkit.slideshow($('.mp-modal-slideshow')[0],{
//         animation:'scroll'
//       });
//     }
//
//
//     $('.mp-modal-trigger').each(function() {
//         $(this).on('click', function(){
//           var mpSlideNo = $(this).attr('data-uk-slideshow-item');
//           var originalDuration = illusionSlider.options.duration;
//           illusionSlider.options.duration = 1;
//           illusionSlider.show(mpSlideNo);
//           illusionSlider.options.duration = originalDuration;
//         });
//     });
//
//   });
// }(window.jQuery, window, document));
