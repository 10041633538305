/**
 * =============================================================================
 * mp.js 
 * =============================================================================
 *
 * Entrypoint for all custom JS for this project. Any JS functionality should be
 * wrapped into Extension and placed in /scripts/vendor named
 * mp-extension-name.js.
 *
 * MP is our global object, attached to the window, with all utilities and
 * extensions
 * =============================================================================
 */

var MP = { 
  PreInitCallbacks: [],
  PostInitCallbacks: [],
  Extensions: {}, 
  Globals: {
    templates: {
        notification: null
    }
  },
  Utilities: { 


    // isCartPage: function(){
    //   return document.querySelectorAll('.template-cart').length > 0;
    // },

    fetchConfig: function(type = 'json') {
      return {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': `application/${type}` }
      };
    },

    /**
     * Show a Bootstrap Alert
     * 
     * @param {String} type 
     * @param {String} content 
     * @param {String} icon 
     */
    alert: function(type = "primary", content = "<strong>This</strong> is an alert", icon = "", closeAfter = 5000) {
      let alert = document.querySelector("#alert-container > .alert");
      alert.className = `${alert.className.replace(/\balert-.+/g, '')} alert-${type}`;
      let alertContent = alert.querySelector(".alert-message");
      content = `<div>${content}</div>`;
      if(icon){
        content = `<i class="fs-4 me-2 ${icon}"></i>${content}`;
      }
      alertContent.innerHTML = content;
      alert.classList.add("show");
      setTimeout(() => {
        alert.classList.remove("show")
      }, closeAfter);
    },

    /**
     * Show a Bootstrap Toast
     * 
     * @param {String} type 
     * @param {String} content 
     * @param {String} icon 
     */
    toast: function(type = "success", content = "<strong>This</strong> is a toast!", icon = "") {
      // Get the template and clone it
      let container = document.querySelector("#toasts");
      let template = document.querySelector('#toast');
      let clone = template.content.firstElementChild.cloneNode(true);

      // Set the alert class, message and optional icon
      let wrapper = clone.querySelector("div:first-child");
      wrapper.className = `${wrapper.className} alert-${type}`;
      clone.querySelector(".toast-message").innerHTML = content;
      if(icon){
        clone.querySelector(".toast-icon").innerHTML = `<i class="fs-4 me-2 ${icon}"></i>`;
      }

      // Add to the DOM, and show
      container.appendChild(clone);
      let toast = new bootstrap.Toast(clone);
      toast.show();
    },

  },

  init: function() {
    this.hasLoaded = true;
    var alertList = document.querySelectorAll('.alert')
    alertList.forEach(function (alert) {
      var alert = new bootstrap.Alert(alert);
    })

    // Run all pre-init callbacks registered before script was loaded 
    while (this.PreInitCallbacks.length){
      try {
        this.PreInitCallbacks.shift().call();
      } catch (e) {
        console.error("[MP] Failed to run callback with error", e);
      }
    }

    // Call onReady method of all extensions once DOMContentLoaded event fired
    document.addEventListener("DOMContentLoaded", function() {
      MP.onReady();
      for (const [key, value] of Object.entries(MP.Extensions)) {
        if("onReady" in value){
          try {
            value.onReady(); 
          } catch (e) {
            console.error(e);
          }
        }
      }
    });

    // Call init method of all extensions straightaway
    for (const [key, value] of Object.entries(MP.Extensions)) {
      if("init" in value){
        try {
          value.init(); 
        } catch (e) {
          console.error(e);
        }
      }
    }

    // Run all post-init callbacks registered before script was loaded 
    while (this.PostInitCallbacks.length){
      try {
        this.PostInitCallbacks.shift().call();
      } catch (e) {
        console.error("[MP] Failed to run post-init callback with error", e);
      }
    }

    // Make all links with # in #MainContent to scroll to the href ID
    document.querySelectorAll("#MainContent a[href*='#']:not([data-hash-ignore])").forEach(element => {
      element.addEventListener("click", e => {
        document.querySelector(element.href).scrollIntoView({
          behavior: 'smooth'
        });
      });
    });
    
  },

  onReady: function() {

  }
}

export default MP;
