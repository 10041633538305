/**
 * =============================================================================
 * theme.js
 * =============================================================================
 * Entry point for all theme Javascript.
 *
 * Before launching a site, consider what imports you actually need - i.e. which
 * parts of Bootstrap, which vendor JS imports, which Bootstrap plugins, etc.
 * =============================================================================
 */

// Imports the laravel default bootstrap stuff
import './bootstrap';
// Imports from node_modules, installed via 'yarn add ...'
import * as bootstrap from 'bootstrap'; // Bootstrap (~97Kb)
import Isotope from "isotope-layout";
// import Swiper, { Navigation, Pagination, Autoplay, Thumbs } from 'swiper'; // Swiper (~??Kb)
// import 'waypoints/lib/noframework.waypoints.min.js'; // Waypoints (intersection observer utility) (~10Kb)
// import Cookies from 'js-cookie'; // Cookies.js (~2Kb)
// import AOS from 'aos'; // Animate on Scroll (~14Kb)
import TomSelect from 'tom-select'; // TomSelect (~52Kb)
// import PhotoSwipe from 'photoswipe'; // PhotoSwipe (~56Kb)
window.imagesLoaded = require('imagesloaded');

// Swiper.use([Navigation, Pagination, Autoplay, Thumbs]);

// Export for others scripts to use by attaching to the window
window.bootstrap = bootstrap;
window.Isotope = Isotope;
// window.Swiper = Swiper;
// window.Cookies = Cookies;
window.TomSelect = TomSelect;
// window.PhotoSwipe = PhotoSwipe;

// Initialisation
// AOS.init({
//   once: true
// });

// Imports from this folder - MP-specific
import MP from './mp';

// import APIGeoapify from './api-geoapify';
// // import AppBackInStock from './app-back-in-stock';
// import Blog from './blog';
// import Cart from './cart';
// import Collection from './collection';
// import CookieBar from './cookie-bar';
// import CountdownManager from './countdown-manager';
// import Facets from './facets';
// import Localization from './localization';
// import ProductFormManager from './product-form-manager';
// import ProductModels from './product-models';
import Custom from './custom';
// import RecentlyViewed from './recently-viewed';

// import ProductTile from "./product-tile";
// customElements.define('product-tile', ProductTile);

// // Export for others scripts to use by attaching to the window
// MP.Extensions.APIGeoapify = APIGeoapify;
// // MP.Extensions.AppBackInStock = AppBackInStock;
// MP.Extensions.Blog = Blog;
// MP.Extensions.Cart = Cart;
// MP.Extensions.Collection = Collection;
// MP.Extensions.CookieBar = CookieBar;
// MP.Extensions.CountdownManager = CountdownManager;
// MP.Extensions.Facets = Facets;
// MP.Extensions.Localization = Localization;
// MP.Extensions.ProductFormManager = ProductFormManager;
// MP.Extensions.ProductModels = ProductModels;
MP.Extensions.Custom = Custom;
// MP.Extensions.RecentlyViewed = RecentlyViewed;

//const carousel = new bootstrap.Carousel("#carouselExampleIndicators");

// Global functions
window.debounce = function(fn, wait) {
  let t;
  return (...args) => {
    clearTimeout(t);
    t = setTimeout(() => fn.apply(this, args), wait);
  };
}

// Kickoff!
window.MP = Object.assign(MP, window.MP);
MP.init();
